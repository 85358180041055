import {
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	Checkbox,
} from "../../../styles/global";
import { TextsProps } from "../../../database/txt.interface";
import { Container, Close, Option } from "./styles";
import moment from "moment";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import CheckboxIcon from "../../../assets/icons/checkbox.svg";
import { P2Desk } from "../../../styles/styles_text";
import { TypesLanguage } from "../../../utils/types";
import { Colors } from "../../../styles/colors";
import ModalConfirmMigrateOrDelete from "../ModalConfirMigrateOrDelete";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import ModalSelectPatientForMigrateExecutions from "../ModalSelectPatientForMigrateExecution";
import { MudarPagina } from "../../../utils/funcs";

interface ModalProps {
	isVisible: boolean
	setIsVisible: (status?:boolean)=>void
	executionList: any
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);

const ModalMigrarExcluir: React.FC<ModalProps> = ({
	isVisible = false,
	setIsVisible,
	executionList
}) => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const language = useSelector((state: any) => state.configs.language);
	const data_selected_delete_or_migrate = useSelector((state:any)=> state.relatorios.data_selected_for_delete_or_migrate)
	const pagina_atual = useSelector(
		(state: any) => state.dashboard.current_page_patient
	);
	const total_pacientes = useSelector(
		(state: any) => state.dashboard.total_pacientes
	);
	const numero_de_paginas = Math.ceil(total_pacientes / 6);
	const [executionsSelected, setExecutionsSelected] = useState<any[]>([]);
	const [modalConfirmVisible, setModalConfirmVisible] =  useState(false);
	const [modalSelectPatientForMigrate, setModalSelectPatientForMigrate] =  useState(false);

	useEffect(()=>{
		dispatch(RelatorioActions.set_items_selected_for_deleted_or_migrate(executionsSelected))
	},[executionsSelected])
	
	useEffect(()=>{
		if(executionList.length === 0){
			setIsVisible(false)
		}
	},[executionList])

	const handleExercise = (execution: any) => {
		let exercise;
		if(execution && language == TypesLanguage.PT) exercise = execution.i.EXERCICIO_TITULO_PT
		if(execution && language == TypesLanguage.EN) exercise = execution.i.EXERCICIO_TITULO_EN
		if(execution && language == TypesLanguage.ES) exercise = execution.i.EXERCICIO_TITULO_ES

		return exercise;
	}
	
	const handleSideExercise = (execution: any) => {
		return execution && execution.i.EXECUCAO_MODO === "D" && current_language?.hemisferio_direito.toLocaleLowerCase() || current_language?.hemisferio_esquerdo.toLocaleLowerCase() ;
	}

	const handleSelectExecution = (execution: any, event: any) => {
		const alreadySelected = executionsSelected.filter((exec) => exec === execution)
		if(alreadySelected.length > 0) {
			const returnOnlyNotToRemove = executionsSelected.filter((exec) => exec !== execution);
			setExecutionsSelected(returnOnlyNotToRemove);
		} else {
			setExecutionsSelected((prevState) => [...prevState, execution]);
		}
	}

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={isVisible}
			>
				<Fade in={isVisible}>
					<Container>
						<Close
							className="close"
							src={buttonClose}
							onClick={() => { setIsVisible(false); setExecutionsSelected([]) }}
							alt="Close"
							style={{width: '1.2rem', height: '1.2rem'}}
						/>
						<div className="divText">
							<h1 className="title">
								Escolha a execução que deseja excluir ou migrar do paciente:
							</h1>
							<p className="text">
							{`${current_language?.exercicio}: ${handleExercise(executionList[0])} ${handleSideExercise(executionList[0])}`}
							</p>
							<div style={{
								scrollbarWidth: 'thin',
								height: '40%',
								overflow: 'auto'
							}}>
								{executionList?.map((execution: any, index: any) => (
									<Option
										style={{justifyContent: 'space-between', cursor: 'pointer'}}
										key={index}
										onClick={(event) => handleSelectExecution(execution, event)}
									>
										<P2Desk style={{ color: Colors.neutral_grey_k_70 }}>{`${execution.x} ${moment(execution.i.EXECUCAO_EXECUTADA).format("DD/MM/YYYY HH:mm:ss").toString()}`} </P2Desk>
										<P2Desk style={{marginRight: '8rem', color: Colors.neutral_grey_k_70}}>{execution.y} Kg</P2Desk>
										<Checkbox
											style={{
												marginRight: "1rem"
											}}
											id={patient.ID_USUARIO.toString()}
											type="checkbox"
											src={CheckboxIcon}
											checked={executionsSelected.filter((exec) => exec === execution).length > 0 && true || false}
										/>
									</Option>
								))}
							</div>
							<div className="ContainerButtons">
								<button 
									className={!executionsSelected.length && "buttonDisabled" || "buttonDelete"}
									onClick={()=> setModalConfirmVisible(true)}
									disabled={!executionsSelected.length}
									>
									<p className={!executionsSelected.length && "txtDisabled" || "txtDelete"}>{current_language?.excluir}</p>
								</button>
								<button 
									className={!executionsSelected.length && "buttonDisabled" || "buttonMigrate"}
									onClick={()=> {
										MudarPagina(
											pagina_atual,
											numero_de_paginas,
											dispatch,
											[3],
										);
										setModalSelectPatientForMigrate(true)
									}}
									disabled={!executionsSelected.length}
									>
									<p className={!executionsSelected.length && "txtDisabled" || "txtMigrate"}>{current_language?.migrar}</p>
								</button>
							</div>
						</div>
					</Container>
				</Fade>
			</Modal>
			<ModalConfirmMigrateOrDelete
				isVisible={modalConfirmVisible}
				setIsVisible={(visible:boolean)=>setModalConfirmVisible(visible)}
				itemList={data_selected_delete_or_migrate}
				typeModal="deleted"
			/>
			<ModalSelectPatientForMigrateExecutions
				isVisible={modalSelectPatientForMigrate}
				setIsVisible={(visible)=>setModalSelectPatientForMigrate(visible)}
			/>
		</div>
	);
};

export default ModalMigrarExcluir;