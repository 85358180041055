import React, { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import ToastAlert from "../../../components/toast";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { FinancialActions } from "../../../store/ducks/financial";
import {
	Conteiner,
	BodyEdite,
	Footer,
	Header,
	ContainerGoBack,
	ContainerTwoLabels,
} from "./styles";
import Text from "../../../components/Texts/Text";
import { ReactComponent as ArrowBack } from "../../../assets/kinologyIcons/svg/arrow_back.svg";

import history from "../../../routes/history";
import Form from "../../../components/Form";
import InputTextEntryForm from "../../../components/Inputs/inputForm";

import { Colors } from "../../../styles/colors";
import FormSubmiteButton from "../../../components/Buttons/FormSubmitButton";
import { ContainerDash, Icon, Return, Scroll } from "../../../styles/global";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { P3Desk } from "../../../styles/styles_text";
import ReturnIcon from "../../../assets/icons/back_page.svg";

const MetodoPagamento: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const cartao = useSelector((state: any) => state.financial.cartao);
	const [name, setName] = useState<string>('');
	const [mes, setMes] = useState<string>('');
	const [ano, setAno] = useState<string>('');

	const methods = useForm();

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);
	useEffect(() => {
		dispatch(
			FinancialActions.financial_request(
				config_servicos.SERVICO_IUGU,
				config_servicos?.SERVICO_SUPERLOGICA
			)
		);
	}, []);

	const onSubmit = (data: any) => {
		const dat_request = {
			SERVICO_IUGU: config_servicos?.SERVICO_IUGU,
			SERVICO_SUPERLOGICA: config_servicos?.SERVICO_SUPERLOGICA,
			CC_NOME: data.nome,
			CC_NUMERO: data.numeroCartao.split(" ").join(""),
			CC_VALIDADE: `${data.mesCartao}/${data.anoCartao}`,
			CC_CVV: data.cvv,
		};
		dispatch(FinancialActions.add_card_request(dat_request));
	};

	useEffect(() => {
		if (cartao.length) {
			setMes(cartao[0]?.CC_VALIDADE.split('/')[0]);
			setAno(cartao[0]?.CC_VALIDADE.split('/')[1]);
			setName(cartao[0]?.CC_NOME);
		}
	}, []);

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("metodoPagameto"));
	}, []);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	return (
		<ContainerDash>
			{is_open_toast && <ToastAlert />}

			<Return
				onClick={() => {
					dispatch(DashBoardActions.set_current_page("pagamentos"));
					dispatch(
						AppActions.set_option_menu(
							false,
							current_language?.menu_lateral_minha_conta
						)
					);
				}}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.pagamentos}</P3Desk>
			</Return>
			<Scroll style={{width: '45%'}}>
				<Form
					style={{ marginTop: 60, width: "100%" }}
					methods={methods}
					onSubmit={(data) => {
						onSubmit(data);
					}}
				>
					<InputTextEntryForm
						type="text"
						title={current_language?.pagamento_numero_cartao + "*"}
						placeholder={current_language?.placeholder_digite}
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						leftIcon="CreditCard"
						typeInputName="numeroCartao"
						width={100}
						fontSize={1}
					/>
					<InputTextEntryForm
						type="text"
						title={current_language?.pagamento_nome_cartao + "*"}
						placeholder={current_language?.placeholder_digite}
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						typeInputName="nome"
						marginTop={60}
						leftIcon="Patient"
						fontSize={1}
						value={name}
					/>
					<ContainerTwoLabels>
						<InputTextEntryForm
							type="text"
							width={48.5}
							title={current_language?.pagamento_mes_expiracao}
							placeholder={current_language?.placeholder_digite}
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							leftIcon="Calendar"
							typeInputName="mesCartao"
							fontSize={1}
							value={mes}
						/>
						<InputTextEntryForm
							type="number"
							width={48.5}
							title={current_language?.pagamento_ano_expiracao + "*"}
							placeholder={current_language?.placeholder_digite}
							leftIcon="Calendar"
							fontFamily="NotoSans-SemiBold"
							fontWeight={600}
							typeInputName="anoCartao"
							fontSize={1}
							value={ano}
						/>
					</ContainerTwoLabels>
					<InputTextEntryForm
						type="number"
						title={"CVV"}
						placeholder={"***"}
						leftIcon="CreditCard"
						fontFamily="NotoSans-SemiBold"
						fontWeight={600}
						marginTop={60}
						typeInputName="cvv"
						fontSize={1}
					/>
					<FormSubmiteButton
						type="submit"
						onClick={() => methods.trigger()}
						disabled={
							Object.values(methods.watch()).every((item: string) => item != "")
								? false
								: true
						}
						marginTop={40}
						fontFamily="Kanit-Medium"
						typeButtonComponent="primary"
						activityIndicator={false}
						title={current_language?.menu_lateral_botao_salvar}
						width={100}
						height={60}
					/>
				</Form>
			</Scroll>
		</ContainerDash>
	);
};

export default memo(MetodoPagamento);
