import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	styled,
	Theme,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, ViewPort } from "./styles";
import store from "../../../store";
import Fatura from "../../../pages/MenueConta/Faturas";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	})
);

const ModalInadimplenteList: React.FC = () => {
	const [ visible, setVisible] = useState<boolean>(false);
	const classes = useStyles();
	const is_inadimplente = useSelector(
		(state: any) => state.app.is_inadimplente
	);

	window.addEventListener('popstate', () => {
		const { app } = store.getState();
		setModal(app?.is_inadimplente);
	}, true);

	const setModal = (open: boolean) => {
		setVisible(open);
	}

	useEffect(()=>{
		setModal(is_inadimplente);
	},[is_inadimplente]);

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={visible}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={visible}>
					<Container>
							<Fatura fromModal={true}/>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalInadimplenteList);
