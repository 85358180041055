import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashBoardActions } from "../../../../../store/ducks/dashboard";
import { PacAvalActions } from "../../../../../store/ducks/manage_pac_aval";
import { QuestionarioSaga } from "../../../../../store/sagas/manage_pac_aval";
import {
	TypeButtonsQuestionnaire,
	TypesMenuInfoProps,
	TypesQuestionnaire,
} from "../../../../../utils/types";
import { Button } from "./styles";
import { AnamneseActions } from "../../../../../store/ducks/anamnese";
import { TextsProps } from "../../../../../database/txt.interface";

interface Props {
	btns: any;
	back: any;
	questionAsync: boolean | undefined;
	loadingFromApi?: boolean
}
const BotoesTypes: React.FC<Props> = ({ btns, back, questionAsync, loadingFromApi }) => {
	const dispatch = useDispatch();

	const botoes = btns.filter(
		(e: any) => e.TELA_CONTEUDO_TIPO.substr(0, 2) === "BT"
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const finalizar = (tipo: string) => {
		dispatch(
			PacAvalActions.set_questionario_respostas_concluido({
				CONCLUIDO: "S",
				TIPO: tipo,
			})
		);
		dispatch(AnamneseActions.salvar_questoes_v7());
		dispatch(DashBoardActions.set_select_info(TypesMenuInfoProps.BTN_MENU));
		dispatch(PacAvalActions.set_questionario(TypesQuestionnaire.MENU));
		if (!questionAsync) {
			dispatch(PacAvalActions.set_reset_questionario());
		}
		QuestionarioSaga();
		dispatch(DashBoardActions.set_current_page("perfil_paciente"));
	};

	function render_button(item: any) {
		const classe =
			item.TELA_CONTEUDO_TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_AVALIACAO ||
			item.TELA_CONTEUDO_TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_TIPO ||
			item.TELA_CONTEUDO_TIPO === TypeButtonsQuestionnaire.BT_FINALIZAR_SF_36 ||
			item.TELA_CONTEUDO_TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_V7 ||
			item.TELA_CONTEUDO_TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_CLINICO_V7
				? "final"
				: (item.TELA_CONTEUDO_TIPO ===
						TypeButtonsQuestionnaire.BT_VOLTAR_ANAMNESE_AVALIACAO ||
						item.TELA_CONTEUDO_TIPO ===
							TypeButtonsQuestionnaire.BT_VOLTAR_ANAMNESE_TIPO ||
						item.TELA_CONTEUDO_TIPO ===
							TypeButtonsQuestionnaire.BT_VOLTAR_SF_36 ||
						item.TELA_CONTEUDO_TIPO ===
							TypeButtonsQuestionnaire.BT_VOLTAR_ANAMNESE_V7 ||
						item.TELA_CONTEUDO_TIPO ===
							TypeButtonsQuestionnaire.BT_VOLTAR_ANAMNESE_CLINICO_V7) &&
				  "voltar";

		return (
			<button
				key={item.TELA_ORDEM}
				className={`${classe}`}
				disabled={!!loadingFromApi}
				onClick={() =>
					classe === "final"
						? finalizar(item.TELA_CONTEUDO_TIPO)
						: classe === "voltar" && back()
				}
			>
				{item.TELA_CONTEUDO}
			</button>
		);
	}

	return (
		<Button questionAsync={questionAsync}>
			{!loadingFromApi && botoes.map((e: any) => {
				return render_button(e);
			})}
		</Button>
	);
};

export default memo(BotoesTypes);
