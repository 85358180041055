import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import BaixarApp from "../components/BaixarApp";
import ModalAdicionarPaciente from "../components/Modais/ModalAdicionarPaciente";
import ModalLinguagem from "../components/Modais/ModalLinguagem";
import { UserProps } from "../interfaces/pacientes.interface";
import AvisoMobile from "../pages/Acesso/AvisoMobile";
import Cadastro from "../pages/Acesso/Cadastro";
import Loading from "../pages/Acesso/Loading";
import Login from "../pages/Acesso/Login";
import RecuperarSenha from "../pages/Acesso/RecuperarSenha";
import TesteModal from "../pages/Acesso/TesteModal";
import Dashboard from "../pages/";
import AlterarSenhaController from "../pages/MenueConta/AlterarSenha/Controller";
import EditarMetodoPagamentoController from "../pages/MenueConta/EditarMetodoPagamento/Controller";
import CentralDeAjuda from "../pages/MenueConta/CentralDeAjuda/Controller";
import CentralDeAjudaItem from "../pages/MenueConta/CentralDeAjudaItem/Controller";
import QuestionarioAsync from "../pages/QuestionarioAsync";
import EditarPerfilController from "../pages/MenueConta/EditarPerfil/Controller";
import FaturasController from "../pages/MenueConta/Faturas/Controller";
import history from "./history";
import UpgradeController from "../pages/MenueConta/Upgrade/Controller";
import AssinaturaController from "../pages/MenueConta/Assinatura/Controller";

export default function Routes() {
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const PrivateRouter = ({ component: Component, ...rest }: any) => {
		return (
			<Route
				{...rest}
				render={(props) =>
					!!user_logado ? (
						<Component {...props} />
					) : (
						<Redirect to={{ pathname: "/", state: { from: props.location } }} />
					)
				}
			/>
		);
	};

	const url_atual = window.location.href;
	let environment = "";
	url_atual.includes("https://web.kinology.com.br/") ||
	url_atual.includes("https://web.kinology.com.br/")
		? (environment = "production")
		: (environment = "development");

	const mobileTrue = window.screen.height > window.screen.width;

	const mobileTeste =
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
			navigator.userAgent
		);

	return (
		<ConnectedRouter history={history}>
			{window.location.pathname !== "/questionario" &&
			(mobileTeste || mobileTrue) ? (
				<Switch>
					<Route path="/aviso" component={AvisoMobile} />
					<Redirect to="/aviso" />
				</Switch>
			) : (
				<Switch>
					<Route path="/aviso" component={AvisoMobile} />
					<Route path="/" exact component={Loading} />
					<Route path="/app" component={BaixarApp} />
					<Route path="/login" component={Login} />
					<Route path="/cadastro" component={Cadastro} />
					<Route path="/idioma" component={ModalLinguagem} />
					<Route path="/recuperar_senha" component={RecuperarSenha} />
					<Route path="/TesteModal" component={TesteModal} />
					<Route path="/central_ajuda" component={CentralDeAjuda} />
					<Route path="/central_ajuda_item" component={CentralDeAjudaItem} />
					<Route path="/questionario" component={QuestionarioAsync} />
					<PrivateRouter path="/dashboard" component={Dashboard} />
					<PrivateRouter
						path="/adicionar_paciente"
						component={ModalAdicionarPaciente}
					/>
					<PrivateRouter
						path="/editar_perfil"
						component={EditarPerfilController}
					/>
					<PrivateRouter
						path="/alterar_senha"
						component={AlterarSenhaController}
					/>
					<PrivateRouter path="/assinatura" component={AssinaturaController} />
					<PrivateRouter path="/faturas" component={FaturasController} />
					<PrivateRouter
						path="/editar_metodo_pagamento"
						component={EditarMetodoPagamentoController}
					/>
					<PrivateRouter path="/upgrade" component={UpgradeController} />
					<Redirect from="*" to="/" />
				</Switch>
			)}
		</ConnectedRouter>
	);
}
