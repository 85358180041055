import React from "react";
import { Provider, useSelector, useStore } from "react-redux";

import ModalAdicionarAvaliador from "./components/Modais/ModalAdicionarAvaliador";
import ModalAdicionarEtiqueta from "./components/Modais/ModalAdicionarEtiqueta";
import ModalAdicionarPaciente from "./components/Modais/ModalAdicionarPaciente";
import ModalAlert from "./components/Modais/ModalAlert";
import ModalCadastroSuccess from "./components/Modais/ModalCadastroSuccess";
import ModalCentraAjuda from "./components/Modais/ModalCentraAjuda";
import ModalCofiguracoesExecucao from './components/Modais/ModalCofiguracoesExecucao';
import ModalCofiguracoesExecucaoForcaMin from './components/Modais/ModalCofiguracoesExecucaoForcaMin';
import ModalCompartilhamento from "./components/Modais/ModalCompartilhamento";
import ModalConecta from "./components/Modais/ModalConecta";
import ModalConecta2 from "./components/Modais/ModalConecta2";
import ModalConecta3 from "./components/Modais/ModalConecta3";
import ModalConecta4 from "./components/Modais/ModalConecta4";
import ModalConexoes from "./components/Modais/ModalConexoes";
import ModalConta from "./components/Modais/ModalConta";
import ModalContagemExercicio from "./components/Modais/ModalContagemExercicio";
import ModalDuvidaExecucao from "./components/Modais/ModalDuvidaExecucao";
import ModalDuvidaExecucaoResultado from "./components/Modais/ModalDuvidaExecucaoResultado";
import EditarAvaliador from "./components/Modais/ModalEditarAvaliador";
import ModalEditarEtiqueta from "./components/Modais/ModalEditarEtiqueta";
import ModalExcluir from "./components/Modais/ModalExcluir";
import ModalExcluirEtiqueta from "./components/Modais/ModalExcluirEtiqueta";
import ModalFotoPerfil from "./components/Modais/ModalFotoPerfil";
import ModalInadimplente from "./components/Modais/ModalInadimplente";
import ModalInadimplenteList from "./components/Modais/ModalInadimplenteList";
import ModalLinguagem from "./components/Modais/ModalLinguagem";
import ModalModoExercicio from "./components/Modais/ModalModoExercicio";
import ModalRecuperar from "./components/Modais/ModalRecuperar";
import ModalSelectAvaliation from "./components/Modais/ModalSelectAvaliation";
import ModalTutorial from "./components/Modais/ModalTutorial";
import ModalUpgrade from "./components/Modais/ModalUpgrade";
import ModalUpgradeFuncionalidade from "./components/Modais/ModalUpgradeFuncionalidade";
import ModalUpgradeFuncionalidadeIndisponivel from "./components/Modais/ModalUpgradeFuncionalidadeIndisponivel";
import ModalUpgradeRelatorios from './components/Modais/ModalUpgradeRelatorios';
import ModalContinuarSF36 from "./components/Modais/ModalContinuarSF_36"
import Routes from "./routes";
import store from "./store";
import ModalContinuarAnamnese from "./components/Modais/ModalContinuarAnamnese";
import ModalBloqueioDino from "./components/Modais/ModalBloqueioDino";
import ModalFilterProcol from "./components/Modais/ModalFiltroProtocolo";
import ModalSatisfacao from "./components/Modais/ModalSatisfacao";
import ModalSatisfacaoExame from "./components/Modais/ModalSatisfacaoExame";
import ModalCadastroCartao from "./components/Modais/ModalAvisoCartao";
import ModalRegisterSuccess from "./components/Modais/ModalRegisterSuccess";
import styled from "styled-components";

import ModalAvaliador from "./components/ModaisHome/ModalAvaliador";
import ModalFotoPerfilAssinatura from "./components/Modais/ModalEditarFotoPerfilAssinatura";
import ModalAlterarSenha from "./components/Modais/ModalAlterarSenha";
import ModalCondicaoFisica from "./components/ModaisHome/ModalCondicaoFisica";
import ModalEtiqueta from "./components/ModaisHome/ModalEtiqueta";
import ModalFiltro from "./components/ModaisHome/ModalFiltro";
import ModalAdicionarAssinatura from "./components/Modais/ModalAdicionarAssinatura";
import ModalNotificacoes from "./components/ModaisHome/ModalNotificacoes";
import ModalExcluirPaciente from "./components/ModaisHome/ModalExcluirPaciente";
import ModalExcluirProtocolo from "./pages/DashProtocolos/ModalExcluirProtocolo";
import ModalAdicionarProtocolo from "./pages/DashProtocolos/AdicionarProtocolo/AdicionarProtocolo";
import ModalEditarProtocolo from "./pages/DashProtocolos/EditarProtocolo/EditarProtocolo";
import ModalExcluirExercicio from "./pages/DashProtocolos/ModalExcluirExercicio";
import ModalAdicionarExercicio from "./pages/DashProtocolos/AdicionarExercicio/AdicionarExercicio";
import ModalEditarExercicio from "./pages/DashProtocolos/EditarExercicio/EditarExercicio";
import Tutorial from "./pages/DashProtocolos/Tutorial/Tutorial";
import ModalOuro from "./pages/Execucao/ModalOuro";
import ModalFimDescanso from "./pages/Execucao/ModalFimDescanso";
import ExcluirAnotacoes from "./pages/Paciente/Anotacoes/ExcluirAnotacao";
import ModalFilterGrupamento from "./pages/Relatorios/Assimetria/EvolucaoAssimetria/ModalFiltroGrupamento";
import ModalEscolhaModo from "./pages/Paciente/Anamnese/ModalEscolhaModo";
import ModalAnamneseAndamento from "./pages/Paciente/Anamnese/ModalAnamneseAndamento";
import ModalConectaHandGripPassos from "./components/Modais/ModalConectaHandGripPassos";
import ModalConectaHandGrip from "./components/Modais/ModalConectaHandGrip";
import ModalLadoDominante from "./components/Modais/ModalLadoDominante";

// test build
const Image = styled.img`
	&.GoNotifications {
		cursor: pointer;
	}
`;

const App = function () {

	return (
		<Provider store={store}>
				
			<Routes />
			<ModalAlert />
			<ModalCadastroSuccess />
			<ModalConecta />
			<ModalConecta2 />
			<ModalConecta3 />
			<ModalConecta4 />
			<ModalConta />
			<ModalLinguagem />
			<ModalFiltro />
			<ModalNotificacoes />
			<ModalExcluir />
			<ModalConexoes />
			<ModalUpgrade />
			<ModalAdicionarPaciente />
			<ModalAdicionarAvaliador />
			<ModalCondicaoFisica />
			<ModalEtiqueta />
			<ModalFotoPerfil />
			<ModalAdicionarEtiqueta />
			<ModalContagemExercicio />
			<ModalSelectAvaliation />
			<ModalCofiguracoesExecucao />
			<ModalCofiguracoesExecucaoForcaMin />
			<ModalDuvidaExecucao />
			<ModalDuvidaExecucaoResultado />
			<ModalCompartilhamento />
			<EditarAvaliador />
			<ModalModoExercicio />
			<ModalRecuperar />
			<ModalEditarEtiqueta />
			<ModalExcluirEtiqueta />
			<Tutorial />
			<ModalCentraAjuda />
			<ModalUpgradeFuncionalidade />
			<ModalUpgradeFuncionalidadeIndisponivel />
			<ModalUpgradeRelatorios />
			<ModalInadimplente />
			<ModalInadimplenteList />
			<ModalContinuarSF36/>
			<ModalContinuarAnamnese/>
			<ModalBloqueioDino/>
			<ModalFilterProcol/>
			<ModalSatisfacao />
			<ModalSatisfacaoExame />
			<ModalCadastroCartao />
			<ModalRegisterSuccess />
			<ModalAvaliador />
			<ModalFotoPerfilAssinatura />
			<ModalAlterarSenha />
			<ModalAdicionarAssinatura />
			<ModalExcluirPaciente />
			<ModalExcluirProtocolo />
			<ModalAdicionarProtocolo />
			<ModalEditarProtocolo />
			<ModalExcluirExercicio />
			<ModalAdicionarExercicio />
			<ModalEditarExercicio />
			<ModalOuro />
			<ModalFimDescanso />
			<ExcluirAnotacoes />
			<ModalFilterGrupamento />
			<ModalEscolhaModo />
			<ModalAnamneseAndamento />
			<ModalConectaHandGripPassos />
			<ModalConectaHandGrip />
			<ModalLadoDominante />
		</Provider>
	);
};

export default App;
