import React, { useEffect, useState } from "react";
import {
	ButtonSave,
	Checkbox,
	ContainerDash,
	Icon,
	ProfilePicture,
	Return,
} from "../../../styles/global";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import CheckboxIcon from "../../../assets/icons/checkbox.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import { useDispatch, useSelector } from "react-redux";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import styled from "styled-components";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { ExecucaoProps } from "../../../interfaces/execucao.interface";
import { AssimetriaPros } from "../../../interfaces/assimetria.interface";
import { AnotacaoProps } from "../../../interfaces/anotacoes.interface";

const typeOfJoint = [
	'QUADRIL',
	'JOELHO',
	'CERVICAL',
	'OMBRO',
	'COTOVELO',
	'PUNHO',
	'TORNOZELO',
];

interface typeKeys {
	key: string;
	title: string;
	isValid: boolean;
}

interface typeKeys {
	key: string;
	title: string;
}

const EmissaoRelatorios: React.FC = () => {
	const dispatch = useDispatch();

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const current_selected_reports = useSelector(
		(state: any) => state.relatorios.current_selected_reports
	);

	const historico_anamenses = useSelector(
		(state: any) => state.anamnese.historico_anamnese
	);

	//Verifica se está disponivel para emissão

	function verifyExecutions() {
		const execucoes: ExecucaoProps[] = useSelector(
			(state: any) => state.dashboard.execucoes
		);

		return execucoes.length > 0 ? true : false;
	}
	function verifyAssimetrys() {
		const assimetrias: AssimetriaPros[] = useSelector(
			(state: any) => state.dashboard.assimetria
		);

		return assimetrias.length > 0 ? true : false;
	}
	function verifyAnamneses() {
		return historico_anamenses?.anamneses.length > 0 ||
			historico_anamenses?.exames_fisio_clinico.length > 0
			? true
			: false;
	}
	function verifyAnotations() {
		const todasAnotacoes: any = useSelector(
			(state: any) => state.dashboard.anotacoes
		);

		const data = todasAnotacoes.filter(
			(filterAnotacoes: AnotacaoProps) =>
				filterAnotacoes.ID_USUARIO == patient.ID_USUARIO
		);

		return data.length > 0 ? true : false;
	}
	function verifyQualityOfLife() {
		return false;
		//return historico_anamenses?.sf36.length > 0 ? true : false;
	}
	function verifyDesequilibrio() {
		const desequilibrios_object: any = useSelector(
			(state: any) => state.dashboard.desequilibrio
		);
			let allowed = false;
	
			const desequilibrios_array: any = !!desequilibrios_object && Object.values(desequilibrios_object) || [];
			if(desequilibrios_array.length > 0 && !!verifyAssimetrys()) {
				typeOfJoint.map((item: any) => {
					desequilibrios_array.map((des: any) => {
						if (des.TITULO_TABELA.toUpperCase() === item) {
								if(des?.REFERENCIAS?.length > 0) allowed = true
						}
					})
				})
			}
			return allowed;
	}
	function verifyPrensaoPalmar() {
		return true;
	}

	const [all_reports, set_all_reports] = useState(true);

	const categorias_relatorios: Array<typeKeys> = [
		{
			key: "ANAMNESE",
			title: "Anamnese Completa",
			isValid: verifyAnamneses(),
		},
		{
			key: "ATENDIMENTO",
			title: "Evolução do Paciente",
			isValid: verifyAnotations(),
		},
		// {
		// 	key: "QUALIDADE_DE_VIDA",
		// 	title: "Qualidade de Vida",
		// 	isValid: verifyQualityOfLife(),
		// },
		{
			key: "ASSIMETRIA",
			title: "Assimetria e Indicativos de Risco (Assimetria)",
			isValid: verifyAssimetrys(),
		},
		{
			key: "EQUILIBRIO_MUSCULAR",
			title: "Histórico de Desequilíbrio Muscular (Índice I/Q)",
			isValid: verifyDesequilibrio(),
		},
		{
			key: "PROGRESSAO_FORCA",
			title: "Histórico de Força e Valores de Referência (Evolução)",
			isValid: verifyExecutions(),
		},
		{
			key: "CURVAS_FORCA",
			title: "Dinâmica de Força e Desempenho Muscular (Força-Tempo)",
			isValid: verifyExecutions(),
		},
		// {
		// 	key: "PREENSAO_PALMAR",
		// 	title: "Prensão Palmar",
		// 	isValid: verifyPrensaoPalmar(),
		// },
	];

	function AddReportSelected(categoria: string) {
		set_all_reports(false);
		let aux_current_selected_reports = current_selected_reports;

		const exists = current_selected_reports.includes(categoria);

		if (exists) {
			aux_current_selected_reports = aux_current_selected_reports.filter(
				(key: string) => key != categoria
			);
		} else {
			aux_current_selected_reports = [
				...aux_current_selected_reports,
				categoria,
			];
		}

		dispatch(
			RelatorioActions.set_current_selected_reports(
				aux_current_selected_reports
			)
		);
	}

	useEffect(() => {
		if (all_reports) {
			const aux_keys: any = [];
			categorias_relatorios.map((item: typeKeys) => {
				if (item.isValid) {
					aux_keys.push(item.key);
				}
			});
			dispatch(RelatorioActions.set_current_selected_reports(aux_keys));
		}
	}, [all_reports]);

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk>Informações do paciente</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "3rem",
					width: "60%",
				}}
			>
				<ProfilePicture
					src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
				/>
				<S1Desk style={{ marginLeft: "1rem" }}>{patient.USUARIO_NOME}</S1Desk>
			</div>
			<WhiteContainer>
				<P2Desk style={{ marginBottom: "1rem" }}>
					Selecione quais informações serão geradas no relatório:
				</P2Desk>
				<Option>
					<Checkbox
						style={{ marginRight: "0.5rem" }}
						id={patient.ID_USUARIO.toString()}
						type="checkbox"
						src={CheckboxIcon}
						checked={all_reports}
						onClick={() => {
							set_all_reports(all_reports ? false : true);
							all_reports == true &&
								dispatch(RelatorioActions.set_current_selected_reports([]));
						}}
					/>
					<P2Desk>Relatório completo</P2Desk>
				</Option>
				{categorias_relatorios.map((categoria: typeKeys, index) => (
					<Option
						style={categoria.isValid ? { opacity: 1 } : { opacity: 0.3 }}
						key={index}
					>
						<Checkbox
							style={
								categoria.key == "RELATORIO_COMPLETO"
									? { marginRight: "0.5rem" }
									: { marginRight: "0.5rem", marginLeft: "1.5rem" }
							}
							id={patient.ID_USUARIO.toString()}
							type="checkbox"
							src={CheckboxIcon}
							checked={
								current_selected_reports.includes(categoria.key) &&
								categoria.isValid
							}
							onClick={() => {
								categoria.isValid && AddReportSelected(categoria.key);
							}}
						/>
						<P2Desk>{categoria.title}</P2Desk>
					</Option>
				))}
			</WhiteContainer>
			<div style={{ width: "60%" }}>
				<ButtonSave
					active={current_selected_reports.length > 0 ? true : false}
					onClick={() => {
						current_selected_reports.length > 0 &&
							dispatch(DashBoardActions.set_current_page("selecao_exercicios"));
					}}
				>
					Avançar
				</ButtonSave>
				<ButtonSave
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("perfil_paciente"))
					}
					style={{
						marginTop: "1rem",
						marginBottom: "1rem",
						backgroundColor: "transparent",
						border: "2px solid #000e4b",
						color: "#000e4b",
					}}
					active={true}
				>
					Voltar
				</ButtonSave>
			</div>
		</ContainerDash>
	);
};

const WhiteContainer = styled.div`
	width: 60%;
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const Option = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;

	padding-top: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
`;
export default EmissaoRelatorios;

