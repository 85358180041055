import moment from "moment";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { UserProps } from "../../interfaces/pacientes.interface";
import { persistStorage } from "../../utils/base_async_storage";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { ConfigsActions, Types } from "../ducks/configs";
import history from "../../routes/history";
import * as Sentry from "@sentry/react";

interface DinamosBlocked {
	DINAMO_UUID: string,
	tipo_bloqueio: string | null
}

function* getConfigs(): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const language: any = yield select((state: any) => state.configs.language);
	const version: any = yield select((state: any) => state.configs.version);
	const dat = {
		TOKEN: user_logado.token,
		ID_USUARIO: user_logado?.ID_USUARIO,
		txPlatform: "Web",
		txVersao: version,
		txIdioma: language.toLowerCase(),
	};

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_configs.php`,
				requestOptions
			);
			const response = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_configs.php", "error");
			throw e;
		}
	}

	function* createConfigUser(data: any) {
		yield put(ConfigsActions.set_alerta_sonoro(data.CONFIG_EXER_SOM));
		yield put(ConfigsActions.set_alerta_vibratorio(data.CONFIG_EXER_VIBRAR));
		yield put(ConfigsActions.set_contagem_regresiva(data.CONFIG_EXER_321));
		yield put(
			ConfigsActions.set_tempo_contracao(data.CONFIG_EXER_CONTRACAO_FLAG)
		);
		yield put(
			ConfigsActions.set_intervalo_descanso(data.CONFIG_EXER_DESCANSO_FLAG)
		);
		yield put(
			ConfigsActions.set_tempo_contracao_timer(data.CONFIG_EXER_CONTRACAO)
		);
		yield put(
			ConfigsActions.set_intervalo_descanso_timer(data.CONFIG_EXER_DESCANSO)
		);
		yield put(
			ConfigsActions.set_config_funcional(data.CONFIG_FUNCIONAL)
		);
	}

	try {
		const data = yield call(apiCall);
		yield call(persistStorage, {
			res: data.config_user,
			tag: "config_user",
		});
		yield call(persistStorage, {
			res: data.config_app,
			tag: "config_app",
		});
		yield call(persistStorage, {
			res: data.config_servicos,
			tag: "config_servicos",
		});
		yield call(createConfigUser, data.config_user);

		yield put(ConfigsActions.set_config_app(data.config_app));
		yield put(ConfigsActions.set_config_servico(data.config_servicos));
		yield put(
			ConfigsActions.set_config_lista_usuarios(data.config_lista_usuarios)
		);
		yield put(ConfigsActions.set_config_relatorio(data.config_relatorios));
		yield put(
			ConfigsActions.set_quantidade_emissao_relatorios(
				data.quantidade_emissao_relatorios
			)
		);
		yield put(ConfigsActions.request_evaluated_patients());

		const modeWeb = data?.config_servicos?.SERVICO_WEB;
		if (modeWeb == false) {
			history.push("/aviso");
			yield put(ConfigsActions.set_remove_button_close(true));
			return;
		}

		if (data?.config_servicos?.SERVICO_INADIMPLENTE == "S") {
			yield put(AppActions.set_modal_inadimplente(true, false));
		}
	} catch (e) {}
}

function* configSalvar({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);

	const dat = {
		TOKEN: user_logado.token,
		txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
		...payload,
	};
	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}configs/configs_usuario_salvar.php`,
				requestOptions
			);
			const response = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		let message: any = "";
		if (response.status == TypesErrorRequest.SUCCESS) {
			yield put(ConfigsActions.config_request());
		}
		if (response.status == TypesErrorRequest.FAIL) {
			if (response.code == -1) {
				message = current_language?.atualizacao_falhou2;
			}

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		}
	} catch (e) {
		Sentry.captureMessage("CAIU NO CATCH: configs_usuario_salvar.php", "error");
	}
}
function* addDino(){
	const dinamo_mac_address: string = yield select(
		(state: any) => state.configs.mac_address
	);
	const dinamo_uuid: string = yield select(
		(state: any) => state.configs.dinamo_uuid
	);
	const device_name: string = yield select(
		(state: any) => state.configs.device_name
	);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall(){

		if(device_name){

			const data_request = {
				TOKEN: user_logado.token,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				DINAMO_NOME: device_name,
				DINAMO_MAC: dinamo_mac_address ? dinamo_mac_address : " ",
				DINAMO_UUID : dinamo_uuid ? dinamo_uuid : " ",
			};
			const requestOptions:{method: string, body: string} = {
				method: "POST",
				body: JSON.stringify(data_request),
			};
			try {
				const  data  = await fetch(
					`${process.env.REACT_APP_BASE_URL}dinamos/dinamos_salvar.php`,
					requestOptions,
				);
	
				const response = await data.json();
				return response
				
			} catch (e) {
				console.log("🚀 ~ file: config.ts:206 ~ function*addDino ~ e:", e)
			}
		}
	}

	try{
		const response: {
			code: number,
			message: string, 
			status: string
		} = yield call(apiCall);
		if(response.status != TypesErrorRequest.SUCCESS  ){
			console.log("🚀 ~ file: config.ts:214 ~ function*addDino ~ response:", response.message)
		};
	}catch(error){
		console.log("🚀 ~ file: config.ts:213 ~ try ~ error:", error)
		Sentry.captureMessage("CAIU NO CATCH: dinamos_salvar.php", "error");
	}

}

function* getBlockedDinos(){

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall(){
			const data_request = {
				TOKEN: user_logado.token,
			};
			const requestOptions:{method: string, body: string} = {
				method: "POST",
				body: JSON.stringify(data_request),
			};
			try {
				const  data  = await fetch(
					`${process.env.REACT_APP_BASE_URL}dinamos/dinamos_verificar.php`,
					requestOptions,
				);
				const response = await data.json();
				return response;
			} catch (e) {
				Sentry.captureMessage("CAIU NO CATCH: dinamos_verificar.php", "error");
			}
	}

	try{
		const response: {
			code: number,
			dinamos_invalidos: Array<DinamosBlocked>,
			status: string,
		} = yield call(apiCall);
		if(response.status === TypesErrorRequest.SUCCESS ){
			yield put(AppActions.set_blocked_dinamos(response.dinamos_invalidos));
		}else{
			console.log("🚀 ~ file: config.ts:245 ~ try ~ error:")
		}
	}catch(error){
		console.log("🚀 ~ file: config.ts:248 ~ try ~ error:", error)
	}

}

function* BlockDino(){
	const blocked_dinamos: Array<DinamosBlocked>  = yield select((state: any) => state.app.dinamos_blocked);
	const dinamo_mac_address: string = yield select(
		(state: any) => state.configs.mac_address
	);

	for(const i in blocked_dinamos){
		if(blocked_dinamos[i].DINAMO_UUID === dinamo_mac_address){
			yield put(AppActions.set_modal_bloqueio_dino(true));
		}
	}
}
function* getNumberOfEvaluatedPatients(){

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const data_request = {
		TOKEN: user_logado.token,
	};
	const requestOptions = {
		method: "POST",
		body: JSON.stringify(data_request),
	};

	async function apiCall(){
		try {
			const  data  = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_pacientes_avaliados.php`,
				requestOptions,
			);

			const response = await data.json();

			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: /sync/sync_pacientes_avaliados.php", "error");
		}
		
	};

	try {
		
		const  data:{pacientes_avaliados:number[]}   = yield call(apiCall);

		yield call(persistStorage, {
			res: data?.pacientes_avaliados,
			tag: 'evaluated_patients',
		});

	}catch(error){
		Sentry.captureMessage("CAIU NO CATCH: sync_pacientes_avaliados.php", "error");
	}
}


export function* ConfigSaga(): any {
	return yield all([
		takeLatest(Types.CONFIG_REQUEST, getConfigs),
		takeLatest(Types.CONFIG_EDIT_REQUEST, configSalvar),
		takeLatest(Types.SET_IDENTIFICATION_DINAMO, addDino),
		takeLatest(Types.BLOCK_DINO_REQUEST, getBlockedDinos),
		takeLatest(Types.SET_IDENTIFICATION_DINAMO, BlockDino),
		takeLatest(Types.VERIFY_BLOCKED_DINO, BlockDino),
		takeLatest(Types.REQUEST_EVALUATED_PATIENTS, getNumberOfEvaluatedPatients),
	]);
}
