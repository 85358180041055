import {
	createStyles,
	FormControl,
	makeStyles,
	MenuItem,
	Select,
	Theme,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as V from "victory";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import {
	EvolucaoProps,
	ExecucaoProps,
} from "../../../interfaces/execucao.interface";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { ConfigsActions } from "../../../store/ducks/configs";
import { getGroupingSide, getPermission } from "../../../utils/funcs";
import {
	EXECUCAO_TIPO,
	TypesLanguage,
	TypesPermission,
} from "../../../utils/types";
import { Item } from "../../Paciente/Informacoes/styles";
import NenhumRelatorio from "../NenhumRelatorio";
import { Buttom, Charts, Content, Exercice, ForcaMaxima, ForcaMed } from "./styles";
import {
	ContainerDash,
	Icon,
	ProfilePicture,
	Return,
} from "../../../styles/global";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Referencia from "../../../assets/kinologyIcons/svg/referencia.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import { P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import PdfIcon from "../../../assets/kinologyIcons/svg/pdf.svg";
import styled from "styled-components";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import MigrarIcon from "../../../assets/kinologyIcons/svg/migrar.svg";
import ModalMigrarExcluir from "../../../components/Modais/ModalMigrarExcluir"
import DropDownButton from "../../../components/DropDownButton";
import { Colors } from "../../../styles/colors";

export interface ExerAfterListProps {
	EXERCICIO_NOME: string;
	ID_EXERCICIO: number;
	MODO: any;
}

const Evolucao: React.FC = () => {
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const [permission, setPermission] = useState(true);

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			button: {
				display: "block",
				marginTop: theme.spacing(2),
				border: "none",
				fontSize: 25,
			},
			formControl: {
				margin: theme.spacing(1),
				border: "none",
				fontSize: 25,
			},
			select: {
				border: 0,
				fontSize: 20,
				fontWeight: "bold",
				fontStyle: "normal",
				color: "#5C5B5B",
			},
		})
	);

	const sharedAxisStyles = {
		axis: {
			stroke: "transparent",
		},
		axisLabel: {
			fontSize: 11,
			padding: 35,
			fontFamily: "roboto",
		},
		tickLabels: {
			fill: "#5C5B5B",
			fontSize: 11,
			fontFamily: "roboto",
		},
	};

	const classes = useStyles();
	const [age, setAge] = React.useState<string>();
	const [open, setOpen] = React.useState(false);

	const dispatch = useDispatch();
	const language = useSelector((state: any) => state.configs.language);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const [finalExers, setFinalExers] = useState<Exercicios2Props[]>([]);
	const [finalExersRelatorios, setFinalExersRelatorios] = useState<
		ExerAfterListProps[]
	>([]);

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setAge(event.target.value as string);
		setCurrentIndex(0);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		!getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_EVOLUCAO,
			dispatch
		)
			? dispatch(AppActions.set_modal_upgrade_funcionalidade(true))
			: setOpen(true);
	};

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);
	const list_options_delete_or_migrate = useSelector((state:any)=> state.relatorios.data_for_delete_or_migrate)

	const opAvalFunci = useSelector((state: any) => state.relatorios.opAvalFunci);

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);

	const [currentIndex, setCurrentIndex] = useState(0);
	const [isVisible, setIsVisible] = useState(false);

	function execucoesUsuario(execucoes: ExecucaoProps) {
		return execucoes.ID_USUARIO === paciente.ID_USUARIO;
	}
	let execfiltered = execucoes.filter(execucoesUsuario);

	useEffect(() => {
		const permissionEvolution: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_EVOLUCAO
		);
		if (!permissionEvolution) {
			setPermission(false);
			dispatch(ConfigsActions.set_title_modal_upgrade(true));
		} else {
			dispatch(ConfigsActions.set_title_modal_upgrade(false));
		}
	}, []);

	function execucoesType(execucoes: ExecucaoProps) {
		if (opAvalFunci == EXECUCAO_TIPO.AVALIACAO) {
			return execucoes.EXECUCAO_TIPO === "a";
		} else {
			return execucoes.EXECUCAO_TIPO === "f";
		}
	}

	// execfiltered = execfiltered.filter(execucoesType);

	function execucoesExercicio(execucoes: ExecucaoProps) {
		return execucoes.ID_EXERCICIO.toString() == age;
	}

	execfiltered = execfiltered.filter(execucoesExercicio);

	const maximas: any = [];
	let E_maximas = execfiltered.length + 1;

	function ExtraiMaximas(execucoes: ExecucaoProps, index: any) {
		E_maximas = E_maximas - 1;
		maximas.push({
			y: execucoes.EXECUCAO_EXECMAX,
			x: `E${E_maximas}: ${moment(execucoes.EXECUCAO_EXECUTADA).format(
				"DD/MM/YY"
			)}`,
			i: execucoes,
		});
	}
	execfiltered.filter(ExtraiMaximas);
	maximas.reverse();

	const medias: any = [];
	let E_medias = execfiltered.length + 1;
	function ExtraiMedias(execucoes: ExecucaoProps, index: any) {
		E_medias = E_medias - 1;
		medias.push({
			y: execucoes.EXECUCAO_EXECMED,
			x: `E${E_medias}: ${moment(execucoes.EXECUCAO_EXECUTADA).format(
				"DD/MM/YY"
			)}`,
			i: execucoes,
		});
	}
	execfiltered.filter(ExtraiMedias);
	medias.reverse();

	const datas: any = [];
	function ExtraiDatas(execucoes: ExecucaoProps, index: number) {
		datas.push(`${moment(execucoes.EXECUCAO_EXECUTADA).format("DD/MM/YY")}`);
	}
	execfiltered.filter(ExtraiDatas);

	const dadosMaxima = {
		y: maximas,
	};

	const dadosMedia = {
		y: medias,
	};

	useEffect(() => {
		dispatch(AppActions.set_number_grafics(execfiltered.length));
		const ids: any = [];
		const modosForExer: any = {};
		execucoes.forEach((e) => {
			if (e.ID_USUARIO == paciente.ID_USUARIO) {
				ids.push(e.ID_EXERCICIO);
				modosForExer[`${e.ID_EXERCICIO}-${e.EXECUCAO_MODO}`] = e.EXECUCAO_MODO;
			}
		});

		const filter = exercicios.filter((e) => {
			return ids.includes(e.ID_EXERCICIO);
		});

		let exers: any = [];
		filter.forEach((exer) => {
			let pro: any;
			for (pro in modosForExer) {
				if (exer.ID_EXERCICIO == pro.split("-")[0]) {
					const filt: any = exers.find(
						(ex: any) => ex.ID_EXERCICIO == exer.ID_EXERCICIO
					);
					if (filt) {
						const add = {
							...exer,
							modo: [...filt.modo, modosForExer[pro]],
						};
						exers = exers.filter(
							(ex: any) => ex.ID_EXERCICIO != exer.ID_EXERCICIO
						);
						exers.push(add);
					} else {
						exers.push({
							...exer,
							modo: [modosForExer[pro]],
						});
					}
				}
			}
		});
		setFinalExers(exers);
	}, []);

	useEffect(() => {
		const finalExers2: any = [];

		function ChangeType() {
			if (opAvalFunci == "AVALIACAO") {
				return "a";
			} else {
				return "f";
			}
		}

		finalExers?.forEach((exer: any, index) => {
			if (exer.EXERCICIO_EXCLUIDO == false) {
				if (
					execucoes.find(
						(item) =>
							item.ID_EXERCICIO == exer.ID_EXERCICIO &&
							item.EXECUCAO_EXCLUIDA == false 
					)
				) {
					exer?.modo?.forEach((e: string) => {
						finalExers2.push({
							EXERCICIO_NOME:
								language == TypesLanguage.PT
									? finalExers[index]?.EXERCICIO_TITULO_PT
									: language == TypesLanguage.EN
									? finalExers[index]?.EXERCICIO_TITULO_EN
									: finalExers[index]?.EXERCICIO_TITULO_ES,
							ID_EXERCICIO: exer?.ID_EXERCICIO,
							MODO: e[0] !== null ? e[0] : "",
						});
					});
				}
			}
		});

		const result: any = Object.values(
			finalExers2.reduce((acc: any, item: any) => {
				if (!acc[item.ID_EXERCICIO]) {
					acc[item.ID_EXERCICIO] = { ...item };
				} else {
					acc[item.ID_EXERCICIO].MODO += item.MODO;
				}
				return acc;
			}, {})
		);	
		setAge(result[0]?.ID_EXERCICIO.toString());
		setFinalExersRelatorios(result);
	}, [finalExers, opAvalFunci]);

	//Faz a divisão das execucoes em partes de até 5 execucoes

	const chunksize = 4;
	const max_force_chunks: any = [];
	const medium_force_chunks: any = [];

	const max_force_chunks_direito: any = [];
	const max_force_chunks_esquerdo: any = [];
	const max_force_chunks_bilateral: any = [];
	const max_force_chunks_sem_divisao: any = [];

	dadosMaxima.y.forEach((item: any) => {
		if (item.i.EXECUCAO_MODO == "D") max_force_chunks_direito.push(item);
		if (item.i.EXECUCAO_MODO == "E") max_force_chunks_esquerdo.push(item);
		if (item.i.EXECUCAO_MODO == "B") max_force_chunks_bilateral.push(item);
		if (item.i.EXECUCAO_MODO == "S") max_force_chunks_sem_divisao.push(item);
	});

	const array_for_grafics = [
		max_force_chunks_direito,
		max_force_chunks_esquerdo,
		max_force_chunks_bilateral,
		max_force_chunks_sem_divisao,
	];

	dadosMedia.y.forEach((item: any) => {
		if (
			!medium_force_chunks.length ||
			medium_force_chunks[medium_force_chunks.length - 1].length == chunksize
		)
			medium_force_chunks.push([]);
		medium_force_chunks[medium_force_chunks.length - 1].push(item);
	});

	//Faz a troca de indices das execucoes
	const all_index = max_force_chunks.length;

	useEffect(() => {
		if (opAvalFunci == EXECUCAO_TIPO.FUNCIONAL || EXECUCAO_TIPO.AVALIACAO) {
			setCurrentIndex(0);
		}
	}, [opAvalFunci]);

	const SpendReport = (increasingORdecreasing: string) => {
		if (increasingORdecreasing === "increasing") {
			if (all_index != currentIndex + 1) {
				setCurrentIndex(currentIndex + 1);
			}
		} else {
			if (currentIndex == 0) {
			} else {
				setCurrentIndex(currentIndex - 1);
			}
		}
	};

	useEffect(() => {
		setIsVisible(false);
	}, [execucoes])

	function _renderGrafic(dados: any, index: number) {
		return (
			<div className="Chart">
				<V.VictoryChart height={300}>
					<defs>
						<linearGradient id={`lineGradient-${index}`}>
							{dados.map((item: any, index: number) => (
								<stop
									key={index}
									offset={index === 0 ? "0%" : index === 1 ? "50%" : "100%"}
									style={{
										stopColor:
											item.y >
											Number(
												dados[0]?.i?.EVOLUCAO.STATUS === "fail"
													? "0"
													: dados[0]?.i?.EVOLUCAO.DADOS["%5"]
											)
												? "#0CD100"
												: "#D12600",
										stopOpacity: 1,
									}}
								/>
							))}
						</linearGradient>
					</defs>
					<V.VictoryLine
						style={{
							data: { stroke: `url(#lineGradient-${index})`, strokeWidth: 5 },
						}}
						data={dados}
					/>
					<V.VictoryLine
						style={{
							data: { stroke: "#97B3AC" },
						}}
						data={dados.map((item: any) => {
							return {
								x: item?.x,
								y:
									dados[0]?.i?.EVOLUCAO.STATUS === "fail"
										? "0"
										: dados[0]?.i?.EVOLUCAO.DADOS["%5"],
							};
						})}
					/>
					<V.VictoryScatter
						data={dados}
						size={9.5}
						style={{
							data: {
								fill: ({ datum }) =>
									datum.y >
									(dados[0]?.i?.EVOLUCAO.STATUS === "fail"
										? "0"
										: dados[0]?.i?.EVOLUCAO.DADOS["%5"])
										? "#0CD100"
										: "#D12600",
							},
							labels: {
								fontFamily: "roboto",
							},
						}}
						labels={({ datum }) => {
							return datum.y;
						}}
						labelComponent={
							<V.VictoryLabel
								dy={-20}
								backgroundStyle={{ fill: "white" }}
								backgroundPadding={3}
								className="label"
								style={{ fontFamily: "roboto", fontSize: 11 }}
							/>
						}
					/>
					{/* {dados.map((item: any, index: number) => (
			
					))} */}

					<V.VictoryAxis
						domain={{
							y: [
								0,
								Math.max(
									...execfiltered.map(
										(exe: ExecucaoProps) => exe.EXECUCAO_EXECMAX
									)
								) + 10,
							],
						}}
						label={current_language?.forca_kg}
						style={{
							...sharedAxisStyles,
							grid: {
								stroke: "transparent",
								strokeWidth: 0.5,
							},
						}}
						dependentAxis
					/>

					{/* <V.VictoryAxis
						tickFormat={() => ""}
						offsetY={0}
					/> */}

					{/* <V.VictoryAxis
						domain={{
							y: [
								0,
								Math.max(
									...execfiltered.map(
										(exe: ExecucaoProps) => exe.EXECUCAO_EXECMAX
									)
								) + 10,
							],
						}}
						label={current_language?.forca_kg}
						style={{
							...sharedAxisStyles,
							grid: {
								stroke: "#C4C4C4",
								strokeWidth: 0.5,
							},
						}}
						dependentAxis
					/> */}
					<V.VictoryAxis
						standalone={true}
						width={2}
						padding={4}
						domainPadding={{ x: [1, -7] }}
						style={{
							...sharedAxisStyles,
						}}
					/>
				</V.VictoryChart>
			</div>
		);
	}

	function handleData(execucoes: EvolucaoProps) {
		console.log('execucoes', execucoes)
		return (
			<>
				{execucoes?.map((execucao: EvolucaoProps) => {
					return (
						<tr key={execucao?.x.split(":", 1)[0]}>
							<th className="value">
								{moment(execucao?.i.EXECUCAO_EXECUTADA).format("DD/MM/YY")}
							</th>

							<th className="value">
								<span>{execucao.i.EXECUCAO_EXECMAX} (Kg)</span>
							</th>

							<th className="value">{execucao?.i.EXECUCAO_EXECMED} (Kg)</th>

							<th className="value">
								{getPermission(
									config_servicos,
									TypesPermission.SERVICO_VALOR_REFERENCIA,
									dispatch
								) && (
									<>
										{execucao.i.EVOLUCAO.STATUS !== "fail" &&
										execucao.i.EVOLUCAO.DADOS["%5"] <
											execucao.i.EXECUCAO_EXECMED ? (
											<svg
												width="13"
												height="12"
												viewBox="0 0 13 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M6.57559 2.89109L6.69902 1.70666C6.74785 1.23068 7.22491 0.9077 7.7068 1.02369C8.43178 1.19868 8.94104 1.82615 8.94104 2.54561V4.06302C8.94104 4.40051 8.94104 4.5695 9.01938 4.69299C9.06392 4.76348 9.1251 4.82298 9.19808 4.86548C9.32687 4.94097 9.50234 4.94097 9.85276 4.94097H10.0653C10.9791 4.94097 11.4358 4.94098 11.717 5.13596C11.9279 5.28246 12.0765 5.49694 12.1356 5.73993C12.2139 6.06491 12.0384 6.47139 11.6869 7.28334L11.512 7.68832C11.4105 7.92287 11.369 8.1762 11.3907 8.42828C11.5152 9.8652 10.2923 11.0756 8.79722 10.9961L3.20344 10.6967C2.59222 10.6642 2.28688 10.6477 2.01106 10.4202C1.7347 10.1927 1.68264 9.95819 1.57908 9.48972C1.35865 8.49308 1.36871 7.46412 1.60859 6.47139C1.76046 5.84742 2.40011 5.51144 3.0607 5.59144C4.81224 5.80143 6.40012 4.582 6.57559 2.89159V2.89109Z"
													stroke="#39E664"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										) : (
											execucao.i.EVOLUCAO.STATUS !== "fail" &&
											execucao.i.EVOLUCAO.DADOS["%5"] >
												execucao.i.EXECUCAO_EXECMED && (
												<svg
													width="12"
													height="10"
													viewBox="0 0 12 10"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M6.10466 7.48704L5.99367 8.43464C5.94976 8.81544 5.52075 9.07384 5.0874 8.98104C4.43544 8.84104 3.97748 8.33904 3.97748 7.76344V6.54943C3.97748 6.27943 3.97748 6.14423 3.90702 6.04543C3.86747 5.98948 3.81238 5.94217 3.74632 5.90743C3.6305 5.84703 3.4727 5.84703 3.15758 5.84703H2.96648C2.14466 5.84703 1.73399 5.84703 1.48112 5.69103C1.29012 5.57234 1.15661 5.40096 1.10471 5.20782C1.03426 4.94782 1.19206 4.62262 1.50814 3.97302L1.66595 3.64902C1.75667 3.46181 1.79383 3.25901 1.77453 3.05701C1.66257 1.90741 2.76235 0.939001 4.10681 1.003L9.13716 1.2422C9.68681 1.2682 9.96139 1.2814 10.2094 1.4634C10.458 1.6454 10.5048 1.83301 10.5979 2.20781C10.7962 3.00516 10.7872 3.8284 10.5714 4.62262C10.4348 5.12182 9.85957 5.39063 9.26552 5.32662C7.6904 5.15862 6.26246 6.13423 6.10466 7.48664V7.48704Z"
														stroke="#FF0000"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											)
										)}
									</>
								)}
							</th>
							{/* <th className="value">{execucao.i.EXECUCAO_TEMPO} (s)</th> */}
							{/* <th className="value">{execucao.i.EXECUCAO_REPS}</th> */}
						</tr>
					);
				})}
			</>
		);
	}

	function _renderTable(execucoes: any) {
		

		function calcularAumentoPercentual(valorAntigo: any, valorNovo: any) {
			const aumento = valorNovo - valorAntigo;
			const aumentoPercentual = (aumento / valorAntigo) * 100;
			return (
				<P2Desk
					style={{
						color:
							Number(aumentoPercentual.toFixed(2)) >= 0 ? "#0CD100" : "#D12600",
					}}
				>
					{Number(aumentoPercentual.toFixed(2)) >= 0
						? "Melhora de "
						: "Piora de "}
					{aumentoPercentual.toFixed(2)}%
				</P2Desk>
			);
		}

		const primeiro = execucoes[0]?.y;
		const anterior = execucoes[execucoes.length - 2]?.y;
		const ultimo = execucoes[execucoes.length - 1]?.y;

		return (
			<div className="divTable">
				<table className="table">
					<thead>
						<tr className="tr">
							<th className="prop">{current_language?.data}</th>
							<th className="prop">{current_language?.maximo}</th>
							<th className="prop">{current_language?.media}</th>
							{getPermission(
								config_servicos,
								TypesPermission.SERVICO_VALOR_REFERENCIA,
								dispatch
							) && <th className="prop">{current_language?.referencia}*</th>}
							{/* <th className="prop">{current_language?.Tempo_total}</th> */}
							{/* <th className="prop">
								{current_language?.config_aval_repeticoes}
							</th> */}
						</tr>
					</thead>
					<tbody>{handleData(execucoes)}</tbody>
				</table>
				<div
					style={{
						width: "100%",
						borderRadius: "0rem 0rem 0.625rem 0.625rem",
						border: "1px solid #F1F2F3",
						padding: "1rem",
					}}
				>
					<Legenda>
						{execucoes.length > 1 && (
							<>
								{calcularAumentoPercentual(anterior, ultimo)} em relação ao
								exame anterior{" "}
							</>
						)}

						{execucoes.length > 2 && (
							<>
								{calcularAumentoPercentual(primeiro, ultimo)} no total de três
								séries.
							</>
						)}
					</Legenda>
					<Legenda>
						<Icon src={Referencia} /> Valor de referência={" "}
						{getPermission(
							config_servicos,
							TypesPermission.SERVICO_VALOR_REFERENCIA,
							dispatch
						) && (
							<>
								{execucoes[0]?.i?.EVOLUCAO.STATUS === "fail"
									? "-"
									: `${execucoes[0]?.i?.EVOLUCAO.DADOS["%5"]} Kg`}
							</>
						)}
					</Legenda>
				</div>
			</div>
		);
	}

	return (
		<ContainerDash>
			<ModalMigrarExcluir isVisible={isVisible} setIsVisible={(status) => setIsVisible(status!)} executionList={list_options_delete_or_migrate}/>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.area_paciente}</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "3rem",
					width: "60%",
					justifyContent: "space-between",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<ProfilePicture
						src={paciente?.USUARIO_FOTO ? paciente?.USUARIO_FOTO : Perfil}
					/>
					<S1Desk style={{ marginLeft: "1rem" }}>
						{paciente.USUARIO_NOME}
					</S1Desk>
				</div>
				<Icon
					style={{ width: "2rem", height: "2rem" }}
					onClick={() => {
						dispatch(DashBoardActions.set_current_page("emissao_relatorios"))
						dispatch(
							RelatorioActions.set_current_selected_reports(["ATENDIMENTO"])
						);
					}}
					src={PdfIcon}
				/>
			</div>

			<WhiteContainer>
				<P2Desk style={{ marginBottom: "2rem" }}>Evolução de Força</P2Desk>
				<Content>
					<Exercice>
						<FormControl>
							{finalExersRelatorios.length > 0 ? (
								<>
									<Escolha style={{ marginBottom: "1rem" }}>
										{current_language?.escolha_visualizar}
									</Escolha>
									<Select
										className={"selectTitle"}
										labelId="demo-controlled-open-select-label"
										id="demo-controlled-open-select"
										open={open}
										value={age ? age : ""}
										onClose={handleClose}
										onOpen={handleOpen}
										onChange={handleChange}
									>
										{finalExersRelatorios.map((item) => (
											<MenuItem
												key={item.ID_EXERCICIO}
												value={item.ID_EXERCICIO}
											>
												{item.EXERCICIO_NOME}
											</MenuItem>
										))}
									</Select>
								</>
							) : (
								<p></p>
							)}
						</FormControl>
						{array_for_grafics.map((chunck: any, index: number) => (
							<>
								{chunck.length > 0 && (
									<>
										<div
											key={index}
											style={{
												width: "100%",
												display: "flex",
												justifyContent: "center",
											}}
										>
											<P2Desk
												style={{ marginTop: "2rem", marginBottom: "2rem", marginLeft: "5rem"  }}
											>
												<strong>
													{chunck?.[0]?.i?.EXERCICIO_TITULO_PT}{" "}
													{getGroupingSide(
														chunck?.[0]?.i?.EXECUCAO_MODO,
														current_language
													)?.toLowerCase()}
												</strong>
											</P2Desk>
											<div style={{ width: "2rem", height: "2rem", marginLeft: "2rem", marginTop: "2.1rem", cursor: "pointer", zIndex: 2 }}>
												<DropDownButton 
													text={current_language?.migrar_ou_excluir}
													icon={MigrarIcon}
													exercise={chunck.slice(-5)}
													setIsVisible={(visible:boolean)=>setIsVisible(visible)}
												/>
											</div>											
										</div>

										<Charts>
											<ForcaMed>
												{_renderGrafic(chunck.slice(-5), index)}
											</ForcaMed>
										</Charts>
										{_renderTable(chunck.slice(-5))}
									</>
								)}
							</>
						))}
					</Exercice>
				</Content>
			</WhiteContainer>
		</ContainerDash>
	);
};

const WhiteContainer = styled.div`
	background-color: white;
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	position: relative;
	z-index: 2;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const Escolha = styled.div`
	color: var(--Color, #000e4b);
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
`;

const Legenda = styled.div`
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	line-height: 2;
`;

export default Evolucao;
