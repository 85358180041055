import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
	PacientesProps,
	UserProps,
} from "../../interfaces/pacientes.interface";
import { AnamneseActions, Types } from "../ducks/anamnese";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { PacAvalActions } from "../ducks/manage_pac_aval";

function* GetHistoricoAnamnesesCompletas({ payload }: any): any {
	const language: any = yield select((state) => state.configs.language);

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const patient: PacientesProps = yield select(
		(state: any) => state.app.is_open_paciente
	);

	async function apiCall() {
		try {
			const dat = {
				...payload,
				txIdioma: language?.toLowerCase(),
				ID_USUARIO: patient.ID_USUARIO,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_historico_questionarios.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		yield put(AnamneseActions.set_historico_anamnese(response));
	} catch (e) {
		Sentry.captureMessage(
			"CAIU NO CATCH: sync_historico_questionarios.php",
			"error"
		);
	}
}

function* GetRelatorioAnamnese({ payload }: any): any {
	const language: any = yield select((state) => state.configs.language);

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const patient: PacientesProps = yield select(
		(state: any) => state.app.is_open_paciente
	);

	async function apiCall() {
		try {
			const dat = {
				...payload,
				txIdioma: language?.toLowerCase(),
				ID_USUARIO: patient.ID_USUARIO,
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_relatorio_anamnese.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		yield put(AnamneseActions.set_relatorio_anamnese(response));
	} catch (e) {
		Sentry.captureMessage(
			"CAIU NO CATCH: sync_relatorio_anamnese.php",
			"error"
		);
	}
}

function* SalvarQuestoesV7({ payload }: any): any {
	yield put(PacAvalActions.set_loading(true));
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const questionario_resposta: any = yield select(
		(state: any) => state.pac_aval.questionario_resposta
	);
	const questionario_resposta_concluido: any = yield select(
		(state: any) => state.pac_aval.questionario_resposta_concluido
	);
	const id_resposta: any = yield select(
		(state: any) => state.pac_aval.id_resposta
	);

	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const language: any = yield select((state) => state.configs.language);
	const questionario = yield select(
		(state: any) => state.pac_aval.questionario
	);
	const questionarioAsync: any = yield select(
		(state: any) => state.pac_aval.questionario_async
	);
	const questionarioAsyncToken: any = yield select(
		(state: any) => state.pac_aval.questionario_async_token
	);
	const tipo_anamnese = yield select(
		(state: any) => state.pac_aval.tipo_anamnese
	);
	const paciente = yield select((state: any) => state.app.is_open_paciente);

	async function apiCall() {
		try {
			const dat: any = {
				TOKEN: user_logado.token,
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				txRespostas: questionario_resposta,
				ID_USUARIO: paciente.ID_USUARIO,
				ID_RESPOSTA: id_resposta,
				txTipo: questionario,
				txIdioma: language.toLowerCase(),
			};
			if (questionario == "ANAMNESE_V7") {
				dat.ANAMNESE_V7_CONCLUIDO =
					questionario_resposta_concluido.CONCLUIDO
						? questionario_resposta_concluido.CONCLUIDO
						: "N";
			} else {
				dat.ANAMNESE_CLINICO_V7_CONCLUIDO =
					questionario_resposta_concluido.CONCLUIDO
						? questionario_resposta_concluido.CONCLUIDO
						: "N";
			}

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res =
				questionario == "ANAMNESE_V7"
					? await fetch(
							`${process.env.REACT_APP_BASE_URL}questionarios/questionario_salvar_anamnese_v7.php`,
							requestOptions
					  )
					: await fetch(
							`${process.env.REACT_APP_BASE_URL}questionarios/questionario_salvar_anamnese_clinico_v7.php`,
							requestOptions
					  );

			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		if (response.ID_RESPOSTA) {
			yield put(PacAvalActions.set_id_resposta(response.ID_RESPOSTA));
		}
		yield put(PacAvalActions.set_loading(false));
	} catch (e) {
		Sentry.captureMessage(
			"CAIU NO CATCH: questionario_salvar_anamnese_v7.php",
			"error"
		);
	}
}

export function* AnamneseSaga(): any {
	return yield all([
		takeLatest(Types.GET_HISTORICO_ANAMNESE, GetHistoricoAnamnesesCompletas),
		takeLatest(Types.GET_RELATORIO_ANAMNESE, GetRelatorioAnamnese),
		takeLatest(Types.SALVAR_QUESTOES_V7, SalvarQuestoesV7),
	]);
}

