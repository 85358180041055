import moment from "moment";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
	PacientesProps,
	UserProps,
} from "../../interfaces/pacientes.interface";
import {
	TypeButtonsQuestionnaire,
	TypeErrors,
	TypesErrorRequest,
	TypesQuestionnaire,
	TypesQuestionnaireAnamneseTipo,
	TypesQuestionnaireTipos,
} from "../../utils/types";
import { AppActions } from "../ducks/app";
import { PacAvalActions, Types } from "../ducks/manage_pac_aval";
import * as Sentry from "@sentry/react";

function* getQuestionario(): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const language: any = yield select((state: any) => state.configs.language);
	const perfil_anamnese_patient: any = yield select(
		(state: any) => state.pac_aval.perfil_anamnese_patient
	);
	const current_qualidade_vida_history = yield select((state: any)=> state.anamnese.current_qualidade_vida_history)


	const dat: any = {
		TOKEN: user_logado.token,
		ID_USUARIO: perfil_anamnese_patient,
		txIdioma: language.toLowerCase(),
	};

	if(current_qualidade_vida_history !== null) dat.ID_RESPOSTA_SF = current_qualidade_vida_history;

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_perfil_anamnese.php`,
				requestOptions
			);
			const response = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);

		yield put(PacAvalActions.set_perfil_anamnese_array(data));
	} catch (e) {}
}

function* getQuestionarioArray(): any {
	yield put(PacAvalActions.set_questionario_request(true));
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const language: any = yield select((state: any) => state.configs.language);
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const questionario: any = yield select(
		(state: any) => state.pac_aval.questionario
	);
	const questionarioAsync: any = yield select(
		(state: any) => state.pac_aval.questionario_async
	);
	const questionarioAsyncToken: any = yield select(
		(state: any) => state.pac_aval.questionario_async_token
	);
	const paciente: PacientesProps = yield select(
		(state: any) => state.app.is_open_paciente
	);

	const tipo_anamnese: any = yield select(
		(state: any) => state.pac_aval.tipo_anamnese
	);
	const usu_continuar_questionario: any = yield select(
		(state: any) => state.pac_aval.usu_continuar_questionario
	);

	let dat: any;

	if (!questionarioAsync) {
		dat =
			
			tipo_anamnese !== "" ||
				questionario === TypesQuestionnaire.ANAMNESE_TIPO 
				||
				questionario === TypesQuestionnaire.ANAMNESE
				|| 
				questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO
				|| 
				questionario === TypesQuestionnaire.ANAMNESE_FISICA
				? {
						TOKEN: user_logado.token,
						txIdioma: language.toLowerCase(),
						txTipo: questionario,
						txCategoria: tipo_anamnese === "ANAMNESE_V7" ? null : tipo_anamnese
				  }
				: questionario === TypesQuestionnaire.SF_36 && {
						TOKEN: user_logado.token,
						txIdioma: language.toLowerCase(),
						txTipo: questionario,
				  };
	} else {
		dat =
		
		tipo_anamnese !== "" ||
		((questionario === TypesQuestionnaire.ANAMNESE_TIPO ||
			questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO) &&
			questionario != "" &&
			questionario != TypesQuestionnaire.MENU &&
			questionario != TypesQuestionnaire.ANAMNESE &&
			questionario != TypesQuestionnaire.ANAMNESE_FISICA &&
				{
					txToken: questionarioAsyncToken,
					txAssincrono: "S",
					txIdioma: language.toLowerCase(),
				}
			);
	}
	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			if (
				(questionario != "" || questionarioAsync) &&
				usu_continuar_questionario === "" &&
				(
					questionarioAsync 
					||
					tipo_anamnese !== "" 
					||
					questionario === TypesQuestionnaire.SF_36
					|| 
					questionario === TypesQuestionnaire.ANAMNESE_TIPO 
					||
					questionario === TypesQuestionnaire.ANAMNESE
					|| 
					questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO
					|| 
					questionario === TypesQuestionnaire.ANAMNESE_FISICA
				)
			) {
				const res = await fetch(
					`${process.env.REACT_APP_BASE_URL}sync/sync_questionarios.php`,
					requestOptions
				);
				const response = await res.json();
				return response;
			}
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);

		if (
			data?.message === TypeErrors?.BAD_REQUEST 
			||
			data?.message === TypeErrors?.BAD_REQUEST 
			||
			data?.message === TypeErrors?.BAD_REQUEST
		) {
			history.back();


			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: current_language?.error_empty_questionnaire,
				})
			);
		} else {
			if (
				usu_continuar_questionario === "" &&
				(
					questionarioAsync 
					||
					tipo_anamnese !== "" 
					||
					questionario === TypesQuestionnaire.SF_36
					|| 
					questionario === TypesQuestionnaire.ANAMNESE
					|| 
					questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO
					|| 
					questionario === TypesQuestionnaire.ANAMNESE_FISICA
					||
					questionario === TypesQuestionnaire.ANAMNESE_TIPO 
				)
			) {

				yield put(PacAvalActions.set_questionario_array(data));
			}
			yield put(PacAvalActions.set_questionario_request(false));
		}
	} catch (e) {}
}

function* getContinuarQuestionario(): any {
	
	yield put(PacAvalActions.set_questionario_request(true));
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const language: any = yield select((state: any) => state.configs.language);
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const paciente: PacientesProps = yield select(
		(state: any) => state.app.is_open_paciente
	);
	const questionario: any = yield select(
		(state: any) => state.pac_aval.questionario
	);

	const tipo_anamnese = yield select(
		(state: any) => state.pac_aval.tipo_anamnese
	);

	const dat =
	tipo_anamnese !== ""
		? {
			TOKEN: user_logado.token,
			txIdioma: language.toLowerCase(),
			txTipo: questionario,
			// txCategoria: tipo_anamnese, 
			ID_USUARIO: paciente.ID_USUARIO,
		}
		: {
			TOKEN: user_logado.token,
			txIdioma: language.toLowerCase(),
			txTipo: questionario,
			ID_USUARIO: paciente.ID_USUARIO,
		};

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};

			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_questionarios.php`,
				requestOptions
			);
			const response = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_questionarios.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (
			data.message === TypeErrors.BAD_REQUEST ||
			data.message === TypeErrors.BAD_REQUEST ||
			data.message === TypeErrors.BAD_REQUEST
		) {
			history.back();

			let message: any = "";

			message = current_language?.error_empty_questionnaire;

			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: message,
				})
			);
		} else {
			yield put(PacAvalActions.set_questionario_array(data));
			yield put(PacAvalActions.set_continuar_questionario(data));
			yield put(PacAvalActions.set_questionario_request(false));
		}
	} catch (e) {}
}

function* salvarQuestionario(): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const questionario_resposta: any = yield select(
		(state: any) => state.pac_aval.questionario_resposta
	);

	const questionario_resposta_concluido: any = yield select(
		(state: any) => state.pac_aval.questionario_resposta_concluido
	);
	const id_resposta: any = yield select(
		(state: any) => state.pac_aval.id_resposta
	);

	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	const language: any = yield select(
		(state) => state.configs.language
	);
	const questionario = yield select(
		(state: any) => state.pac_aval.questionario
	);
	const questionarioAsync: any = yield select(
		(state: any) => state.pac_aval.questionario_async
	);
	const questionarioAsyncToken: any = yield select(
		(state: any) => state.pac_aval.questionario_async_token
	);
	const tipo_anamnese = yield select(
		(state: any) => state.pac_aval.tipo_anamnese
	);
	const paciente = yield select((state: any) => state.app.is_open_paciente);

	let new_tipo_anamnese: any = "";
	if (questionario === TypesQuestionnaire.ANAMNESE_V7 || questionario === TypesQuestionnaire.ANAMNESE_CLINICO_V7) return false;

	if (questionario === TypesQuestionnaire.ANAMNESE_TIPO) {
		new_tipo_anamnese =
			tipo_anamnese === TypesQuestionnaireAnamneseTipo.SAUDE
				? TypesQuestionnaireTipos.ANAMNESE_TIPO_SAUDE
				: tipo_anamnese === TypesQuestionnaireAnamneseTipo.REABILITACAO
				? TypesQuestionnaireTipos.ANAMNESE_TIPO_REABILITACAO
				: tipo_anamnese === TypesQuestionnaireAnamneseTipo.ESTETICO
				? TypesQuestionnaireTipos.ANAMNESE_TIPO_ESTETICO
				: tipo_anamnese === TypesQuestionnaireAnamneseTipo.ESPORTIVO
				? TypesQuestionnaireTipos.ANAMNESE_TIPO_ESPORTIVO
				: tipo_anamnese;
	} else if (questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO) {
		new_tipo_anamnese =
			tipo_anamnese === TypesQuestionnaireAnamneseTipo.SAUDE
				? TypesQuestionnaireTipos.ANAMNESE_AVALIACAO_SAUDE
				: tipo_anamnese === TypesQuestionnaireAnamneseTipo.REABILITACAO
				? TypesQuestionnaireTipos.ANAMNESE_AVALIACAO_REABILITACAO
				: tipo_anamnese === TypesQuestionnaireAnamneseTipo.ESTETICO
				? TypesQuestionnaireTipos.ANAMNESE_AVALIACAO_ESTETICO
				: tipo_anamnese === TypesQuestionnaireAnamneseTipo.ESPORTIVO
				? TypesQuestionnaireTipos.ANAMNESE_AVALIACAO_ESPORTIVO
				: tipo_anamnese;
	} else {
		new_tipo_anamnese = tipo_anamnese;
	}

	let dat: any;
	if (!questionarioAsync) {
		dat =
			questionario_resposta_concluido.CONCLUIDO === "S" &&
			questionario_resposta_concluido.TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_AVALIACAO &&
			id_resposta !== ""
				? {
						TOKEN: user_logado.token,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: [],
						ID_USUARIO: paciente.ID_USUARIO,
						ANAMNESE_AVALIACAO_CONCLUIDO: "S",
						ID_RESPOSTA: id_resposta,
						txTipo: new_tipo_anamnese.trim(),
						txIdioma: language.toLowerCase()
				  }
				: questionario_resposta_concluido.CONCLUIDO === "S" &&
				  questionario_resposta_concluido.TIPO ===
						TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_TIPO &&
				  id_resposta !== ""
				? {
						TOKEN: user_logado.token,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: [],
						ID_USUARIO: paciente.ID_USUARIO,
						ANAMNESE_TIPO_CONCLUIDO: "S",
						ID_RESPOSTA: id_resposta,
						txTipo: new_tipo_anamnese.trim(),
						txIdioma: language.toLowerCase()
				  }
				: questionario_resposta_concluido.CONCLUIDO === "S" &&
				  questionario_resposta_concluido.TIPO ===
						TypeButtonsQuestionnaire.BT_FINALIZAR_SF_36 &&
				  id_resposta !== ""
				? {
						TOKEN: user_logado.token,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: [],
						ID_USUARIO: paciente.ID_USUARIO,
						SF_QUALIDADE_CONCLUIDO: "S",
						ID_RESPOSTA: id_resposta,
						txTipo: new_tipo_anamnese.trim(),
						txIdioma: language.toLowerCase()
				  }
				: (questionario === TypesQuestionnaire.ANAMNESE_TIPO ||
						questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO) &&
				  id_resposta === ""
				? {
						TOKEN: user_logado.token,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: questionario_resposta,
						ID_USUARIO: paciente.ID_USUARIO,
						txTipo: new_tipo_anamnese.trim(),
						txIdioma: language.toLowerCase()
				  }
				: (questionario === TypesQuestionnaire.ANAMNESE_TIPO ||
						questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO) &&
				  id_resposta != ""
				? {
						TOKEN: user_logado.token,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: questionario_resposta,
						ID_USUARIO: paciente.ID_USUARIO,
						ID_RESPOSTA: id_resposta,
						txTipo: new_tipo_anamnese.trim(),
						txIdioma: language.toLowerCase()
				  }
				: id_resposta != ""
				? {
						TOKEN: user_logado.token,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: questionario_resposta,
						ID_USUARIO: paciente.ID_USUARIO,
						ID_RESPOSTA: id_resposta,
						txIdioma: language.toLowerCase()
				  }
				: {
						TOKEN: user_logado.token,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: questionario_resposta,
						ID_USUARIO: paciente.ID_USUARIO,
						txIdioma: language.toLowerCase()
				  };
	} else {
		dat =
			questionario_resposta_concluido.CONCLUIDO === "S" &&
			questionario_resposta_concluido.TIPO ===
				TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_AVALIACAO &&
			id_resposta !== ""
				? {
						txToken: questionarioAsyncToken,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: [],
						ANAMNESE_AVALIACAO_CONCLUIDO: "S",
						ID_RESPOSTA: id_resposta,
						txAssincrono: "S",
						txIdioma: language.toLowerCase()
				  }
				: questionario_resposta_concluido.CONCLUIDO === "S" &&
				  questionario_resposta_concluido.TIPO ===
						TypeButtonsQuestionnaire.BT_FINALIZAR_ANAMNESE_TIPO &&
				  id_resposta !== ""
				? {
						txToken: questionarioAsyncToken,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: [],
						ANAMNESE_TIPO_CONCLUIDO: "S",
						ID_RESPOSTA: id_resposta,
						txAssincrono: "S",
						txIdioma: language.toLowerCase()
				  }
				: questionario_resposta_concluido.CONCLUIDO === "S" &&
				  questionario_resposta_concluido.TIPO ===
						TypeButtonsQuestionnaire.BT_FINALIZAR_SF_36 &&
				  id_resposta !== ""
				? {
						txToken: questionarioAsyncToken,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: [],
						SF_QUALIDADE_CONCLUIDO: "S",
						ID_RESPOSTA: id_resposta,
						txAssincrono: "S",
						txIdioma: language.toLowerCase()
				  }
				: (questionario === TypesQuestionnaire.ANAMNESE_TIPO ||
						questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO) &&
				  id_resposta === ""
				? {
						txToken: questionarioAsyncToken,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: questionario_resposta,
						txAssincrono: "S",
						txIdioma: language.toLowerCase()
				  }
				: (questionario === TypesQuestionnaire.ANAMNESE_TIPO ||
						questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO) &&
				  id_resposta != ""
				? {
						txToken: questionarioAsyncToken,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: questionario_resposta,
						ID_RESPOSTA: id_resposta,
						txAssincrono: "S",
						txIdioma: language.toLowerCase()
				  }
				: id_resposta != ""
				? {
						txToken: questionarioAsyncToken,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: questionario_resposta,
						ID_RESPOSTA: id_resposta,
						txAssincrono: "S",
						txIdioma: language.toLowerCase()
				  }
				: {
						txToken: questionarioAsyncToken,
						txDataHora: moment(new Date())
							.format("YYYY-MM-DD HH:mm:ss")
							.toString(),
						txRespostas: questionario_resposta,
						txAssincrono: "S",
						txIdioma: language.toLowerCase()
				  };
	}

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const linkEndPoint =
				questionario === TypesQuestionnaire.ANAMNESE_TIPO
					? questionarioAsync
						? "questionario_salvar_assincrono"
						: "questionario_salvar_anamnese_tipo"
					: questionario === TypesQuestionnaire.ANAMNESE_AVALIACAO
					? questionarioAsync
						? "questionario_salvar_assincrono"
						: "questionario_salvar_anamnese_avaliacao"
					: questionario === TypesQuestionnaire.SF_36 && questionarioAsync
					? "questionario_salvar_assincrono"
					: "questionario_salvar_sf36";
			if (
				questionario_resposta != "" ||
				dat.ANAMNESE_AVALIACAO_CONCLUIDO === "S" ||
				dat.ANAMNESE_TIPO_CONCLUIDO === "S" ||
				dat.SF_QUALIDADE_CONCLUIDO === "S"
			) {
				const res = await fetch(
					`${process.env.REACT_APP_BASE_URL}questionarios/${linkEndPoint}.php`,
					requestOptions
				);
				const response = await res.json();
				return response;
			}
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: questionario_salvar_anamnese_avaliacao.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.code === 0 && data.ID_RESPOSTA) {
			yield put(PacAvalActions.set_id_resposta(data.ID_RESPOSTA));
		}

		if (
			!!questionario_resposta_concluido.CONCLUIDO &&
			questionario_resposta_concluido.CONCLUIDO === "S"
		) {
			if (data.status == TypesErrorRequest.FAIL) {
				let message: any = "";

				if (data.code == 1) {
					message = current_language?.adicao_falhou;
				}

				if (data.code == -1) {
					message = current_language?.adicao_falhou;
				}

				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			} else {
				let message: any = "";
				//msg de sucesso
				if (data.code == 0) {
					message = current_language?.adicao_concluida;
				}
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
				yield put(PacAvalActions.set_id_resposta(""));
			}
			yield put(
				PacAvalActions.set_perfil_anamnese_patient(paciente.ID_USUARIO)
			);
		}
	} catch (e) {
	} finally {
		yield put(PacAvalActions.set_array_resposta([]));
	}
}

export function* QuestionarioSaga(): any {
	return yield all([
		takeLatest(Types.SET_PERFIL_ANAMNESE_PATIENT, getQuestionario),
		takeLatest(Types.SET_USU_CONTINUAR_QUESTIONARIO, getContinuarQuestionario),
		takeLatest(Types.SET_QUESTIONARIO, getQuestionarioArray),
		takeLatest(Types.SET_QUESTIONARIO_RESPOSTA, salvarQuestionario),
		takeLatest(Types.SET_QUESTIONARIO_RESPOSTA_CONCLUIDO, salvarQuestionario),
	]);
}
