import {
	Backdrop,
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { Container } from "./styles";
import DinoBad from "../../../assets/dinoBadAsync.svg";
import WhatsAppIcon from "../../../assets/icons/whatsapp.svg";
import store from "../../../store";
import { AuthActions } from "../../../store/ducks/auth";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	})
);

const ModalInadimplente: React.FC = () => {
	const dispatch = useDispatch();
	const [ visible, setVisible] = useState<boolean>(false);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const classes = useStyles();

	const is_open_inadimplente = useSelector(
		(state: any) => state.app.is_open_modal_inadimplente
	);

	window.addEventListener('popstate', () => {
		const { app } = store.getState();
		setModal(app?.is_inadimplente);
	}, true);

	const setModal = (open: boolean) => {
		setVisible(open);
	}

	useEffect(()=>{
		setModal(is_open_inadimplente);
	},[is_open_inadimplente]);

	function handleFaturas (){
		setVisible(false);
		setTimeout(()=>{
			dispatch(AppActions.set_modal_inadimplente(true, true))
		},200)
	}

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={visible}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={visible}>
					<Container>
						<img src={DinoBad} alt="" />
						<p className="title">
							{current_language?.problema_pagamento_assinatura}
						</p>
						<p className="subtitle">
							{current_language?.normalize_situacao}
						</p>
						<button
							onClick={() => handleFaturas()}
							className="buttom"
						>
							{current_language?.menu_lateral_faturas}
						</button>
						<button
							onClick={() => {dispatch(AuthActions.auth_logout()), setVisible(false)}}
							className="buttomLogout"
						>
							<p>{current_language?.sair_modal}</p>
						</button>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default memo(ModalInadimplente);
